@tailwind base;
@tailwind components;
@tailwind utilities;

/* import 'bootstrap/dist/css/bootstrap.min.css'; */
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit !important;
}

* {
  outline: none !important;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
  /* list-style: none; */
}
a:focus,
a:hover {
  text-decoration: none !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 150px !important;
}
.css-1uccc91-singleValue {
  color: #6a757e !important;
  font-weight: 700;
}
a {
  text-decoration: none !important;
}
body {
  font-size: 12pt !important;
}
.wrap {
  padding: 15px;
  border: solid thin #eae7e7;
  border-radius: 6px;
}
.msg-error {
  @apply tw-mt-2 tw-py-2 tw-px-3 tw-border tw-border-[#e20101] tw-text-[#d21c06] tw-text-sm tw-transition-all tw-duration-300 tw-rounded-sm;
}
.input-form {
  padding: 0 !important;
  font-size: 14pt !important;
  border: none !important;
  font-weight: 700 !important;
  color: #333 !important;
  height: auto !important;
  resize: none !important;
  line-height: 1.2 !important;
}
